<template>
  <section class="app-ecommerce-details" v-if="this.postData">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Post Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px">
      <b-col cols="12">
        <p class="h1">
          <b-badge variant="primary" v-if="postData.post_category">{{
            postData.post_category.post_category_translations.name
          }}</b-badge>
        </p>
      </b-col>
      <b-col md="9">
        <b-card title="Cover" class="h-100 text-center">
          <CoolLightBox
            :items="coverArr"
            :index="imgIndex"
            @close="imgIndex = null"
          >
          </CoolLightBox>
          <b-img
            @click="imgIndex = 0"
            :src="postData.cover"
            class="rounded"
            width="400px"
            variant="light"
          />
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card class="h-100">
          <div v-show="delCat == true" class="">
            <b-button
              block
              v-if="authPermissions.includes('update')"
              :to="{ path: '/posts/update/' + postData.id }"
              variant="primary mr-1 mt-1"
            >
              Update
            </b-button>
            <b-button
              @click="deleteData(id)"
              block
              v-if="authPermissions.includes('delete')"
              variant="outline-danger"
              class=""
            >
              Delete
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              v-show="delCat == false"
              @click="restorePost(id)"
              block
              v-if="authPermissions.includes('update')"
              variant="outline-warning"
              class="mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>
          <hr />
          <div
            v-if="delCat"
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ postData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ postData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            "
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ postData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ postData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-row>
          <b-col md="9">
            <b-row>
              <b-col cols="12">
                <b-card title="Title">
                  <h1>{{ postData.title }}</h1>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card title="Body">
                  <p v-html="postData.body"></p>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            @click="
              $router.push({ path: '/ShowPhoneGuide/' + postData.item.id })
            "
            v-if="postData.item"
            md="3"
          >
            <b-card title="Item">
              <b-row>
                <b-col cols="12" class="text-center">
                  <b-img :src="postData.item.cover" width="200px"> </b-img>
                </b-col>
                <b-col cols="12" class="text-center">
                  <p class="h3">{{ postData.item.item_translations.name }}</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <!-- comments -->
        <b-card title="Comments">
          <div
            v-for="(comment, ind) in postData.reviews"
            :key="ind"
            class="d-flex w-100 align-items-start mb-1"
          >
            <b-row class="w-100">
              <b-col cols="1">
                <b-avatar
                  :src="comment.user_profile"
                  size="34"
                  class="mt-25 mr-75"
                />
              </b-col>
              <b-col cols="9">
                <div class="profile-user-info w-100">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h6
                      class="mb-0"
                      :to="{ path: '/User/Show/' + comment.user_id }"
                    >
                      {{ comment.username }}
                    </h6>
                  </div>
                  <ul class="unstyled-list list-inline pl-1">
                    <li
                      v-for="star in 5"
                      :key="star"
                      class="ratings-list-item mr-25"
                    >
                      <feather-icon
                        icon="StarIcon"
                        size="18"
                        :class="[
                          { 'fill-current': star <= comment.rate },
                          star <= comment.rate ? 'text-warning' : 'text-muted',
                        ]"
                      />
                    </li>
                  </ul>
                  <small>{{ comment.body }}</small>
                </div>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="authPermissions.includes('delete')"
                  @click="deleteReview(comment.id)"
                  size="sm"
                  variant="danger"
                  >Remove</b-button
                >
              </b-col>
              <b-col cols="12">
                <hr style="width: 100%" />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  components: {
    // BSV
    CoolLightBox,
  },
  data() {
    return {
      id: 0,
      PostID: null,
      postData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      imgIndex: null,
      imgIndex1: null,
      coverArr: [],
      delCat: true,
      options: [],
      currentPage: 0,
    }
  },
  mounted() {
    // rquest post data
  },
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('posts/' + this.id)
        .then((result) => {
          this.postData = result.data.data
          this.coverArr.push(this.postData.cover)
          if (this.postData.deleted_at != null) {
            this.delCat = false
          } else {
            this.delCat = true
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete function to delete post
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete post.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('posts/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restorePost(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore post.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('posts/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
